const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-welcome',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `Velkommen til første del af hygiejnetræningen. Jeg er din kollega og hjælper dig gennem spillet. `,
			en: `Welcome to the first part of the hygiene training. I'm your colleague, and I'll help you through the game. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-welcome-2',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du. `,
			en: `In the game you earn points for solving tasks. The fewer mistakes you make, the more points you get. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-welcome-3',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `Når du får point, kan du også få stjerner. I toppen af skærmen kan du se, hvor mange stjerner og point du har. `,
			en: `When you get points, you can also gain stars. At the top of the screen, you can see how many stars and points you have. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-welcome-4',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `Du skal have tre stjerner for at gå videre til anden del af træningen.`,
			en: `You need three stars to proceed to the second part of the training. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-welcome-5',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Du ved det sikkert allerede, men det er vigtigt at understrege, at vi på IFF Brabrand arbejder med forskellige zoner. Forskellige zoner har hver deres krav i forhold til hygiejne. `,
			en: `You probably know already, but it's important to understand that we work with different zones at IFF Brabrand. Different zones have different requirements when it comes to hygiene. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-coloured-zones',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Vi har gule zoner og grønne zoner. Den her træning handler primært om de gule zoner. Hvad gælder for gule zoner?`,
			en: `We have yellow zones and green zones. This training is primarily about the yellow zones. What applies here?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `De er godkendt til afvejning og fremstilling af ingredienser og fødevarer`, 
					en: 'They are approved for weighing and production of ingredients & food',
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager pose-1',
							text: {
								da: `Lige præcis. Og derfor er hygiejnekravene skrappest her.`,
								en: `Exactly. And that's why the hygiene standards are more strict here.`
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Det er for det meste lagre`, 
					en: 'They are mostly storage rooms',
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Normalt er lagre grønne zoner. Enkelte lagerrum har dog godkendte afvejningsområder, og så vil de være gule zoner.`,
								en: `As a general rule, storage rooms are green zones. Some storage room, however, are approved for weighing and will be yellow zones in those cases. `
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Der er krav til personlig hygiejne fra fødevaremyndigheder`, 
					en: 'They have personal hygiene requirements from the authorities',
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Det er her man spiser sin frokost`, 
					en: `It's where you eat your lunch`,
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-colour-marking',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Zonerne er tydeligt markeret med farver ved indgangen. Og nogle gule områder har endda ekstra krav - det vender vi tilbage til. `,
			en: `The zones are clearly marked with colours at the entrance. And some yellow areas can even have extra requirements - we'll get back to that. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-before-going-in',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `Inden vi går indenfor, så skal vi tale lidt om den rigtige påklædning. `,
			en: `Before we go inside, let's talk about how to dress properly. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-yellow-zones',
		layout: 's1-m1-yellow-zones',
		subtype: 'images',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `I gule zoner skal du bære hår- og skægnet. Hvordan skal hårnet og skægnet sidde? Tryk på de rigtige billeder.`,
			en: `In yellow zones, you must wear hairnet and beard snood. How should you use those? Click on the correct images. `
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Billede af alt hår, men ikke ører dækket af hårnet. Ingen skæg. 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Billede af alt hår, og alt skæg dækket. Skægbind dækker IKKE overskæg
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Hvis dit skæg er under 2mm behøver du ikke bære skægnet. Du behøver heller ikke have det over overskægget. `,
								en: `If your beard is less than 2mm long, you don't have to wear a beard snood. Also, you don't have to cover your moustache. `
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Noget hår stikker ud. Intet skæg.
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Alt hår dækket. Skæg OVER 2mm. Ingen skægbind.
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // helt skaldet, ingen skæg, ingen hårnet eller skægnet
				isCorrect: true
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-jewelry',
		layout: 's1-m1-jewelry',
		subtype: 'images',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvilke smykker må du IKKE have på i de gule zoner?`,
			en: `What jewellery is not allowed in yellow zones?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Armbånd
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Næsepiercing
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Navlepiercing
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Armbåndsur
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Halskæde
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Glat vielsesring
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-iff-workclothes-yellow-zones',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `I gule zoner skal du bære pilotarbejdstøj fra IFF eller en kittel ud over dit almindelige tøj. Hvilke krav er der til kitler og arbejdstøj?`,
			en: `In yellow zones, you must wear pilot work clothes from IFF or a lab coat over your private clothes. What apllies to your work clothing?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Det skal være rent`, 
					en: 'It must be clean',
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Det må ikke lugte stærkt`, 
					en: `It mustn't smell strongly`,
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Det skal sidde løst`, 
					en: `It must be loose fitting`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det bestemmer du selv.`,
								en: `That's entirely up to you.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Mine initialer skal stå i nakken`, 
					en: `My initials should be in the collar`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Jeg skal selv vaske det`, 
					en: `I must wash it myself`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nej, det gør vores eksterne vaskeri.`,
								en: `No, we have an external company to do that.`
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-lets-go-in',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `Okay, lad os gå indenfor.`,
			en: `Okay, let's go inside. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-before-hygiene-zone',
		background: 'dressing-room',
		characters: ['manager coat-2'],
		text: {
			da: `Inden vi går videre ind i selve hygiejnezonen, hvor der bliver fremstillet fødevarer, skal vi kigge på håndhygiejne. `,
			en: `Before moving on to the hygiene zone where foodstuff is made, let's take a look at hand hygiene. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-washing-hands',
		layout: 's1-m1-washing-hands',
		subtype: 'images',
		background: 'dressing-room',
		text: {
			da: `Håndhygiejne er ekstremt vigtigt. Der er især tre områder, som er nemme at glemme under håndvask. Hvilke er det?`,
			en: `Hand hygiene is extremely important. And three areas are especially easy to forget when washing your hands. What are they?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Håndfladen
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Håndryggen
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Neglene
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Håndled
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Mellem fingre
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-nails',
		layout: 's1-m1-nails',
		subtype: 'images',
		background: 'dressing-room',
		text: {
			da: `Håndhygiejne handler også om neglene. 
			<br /><br /><b>Tryk på de billeder, som er forkerte.</b>`,
			en: `Hand hygiene is also about the nails. 
			<br /><br /><b>Click on the images that are incorrect.</b>`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Korte og rene negle
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // korte med neglelak
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja. For hvis du har neglelak på, så skal du bære handsker.`,
								en: `Yes. If you are wearing nail polish, you must also wear gloves. `
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Korte let beskidte negle
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Lange let beskidte negle
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Kunstig negl
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja, for hvis du har kunstige negle, så skal du bære handsker. `,
								en: `Yes. If you have artificial nails, you must also wear gloves. `
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Lang ren negl
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Negle skal være kortklippede, ellers skal du tage handsker på`,
								en: `Nails must be short. Otherwise you have to wear gloves. `
							}
						})
					})
				],
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-when-washing-hands',
		background: 'dressing-room',
		showRequiredNumberOfAnswers: false,
		shuffleOptions: false,
		text: {
			da: `Efter hvilke situationer skal du huske at vaske hænder?
			<br /><br />
			<b>Vælg 1 - og husk at læse alle mulighederne:</b>`,
			en: `After which situations must you remember to wash your hands?
			<br /><br />
			<b>Choose 1 - and remember to read all the options:</b>`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Ved opstart`, 
					en: `When starting work`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Efter pauser`, 
					en: `After breaks`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Efter spisning`, 
					en: `After eating`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Efter toiletbesøg`, 
					en: `After going to the toilet`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Efter rygning`, 
					en: `After smoking`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `I alle ovenstående situationer`, 
					en: `In all above situations`,
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-if-no-sink',
		background: 'dressing-room',
		characters: ['manager coat-1'],
		text: {
			da: `Hvis området, du skal ind til, ikke har en sluse med håndvask, så husk at gå hen til, hvor du kan vaske hænder. `,
			en: `If the area you are entering does not have a sluice with a sink, remember to find a place to wash your hands. `,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-dressing-room-outfit',
		layout: 's1-m3-dressing-room-outfit',
		background: 'dressing-room',
		text: {
			da: `Tryk på de 4 fejl på billedet her. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle `,
			en: `Click on the 4 issues on this screen. Click OK when you are ready, or if you can't find them all`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hair-sticking', // Hår stikker ud under hårnet
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'brown-plaster' // Tydeligt brunt plaster på hånd
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'necklace', // Halskæde
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'dirty-shoes', // Snavsede sko
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-wounds',
		layout: 's1-m1-wounds',
		subtype: 'images',
		background: 'dressing-room',
		text: {
			da: `I den forrige opgave så du, at et almindeligt brunt plaster var en fejl. Men hvad skal man så bruge, hvis man har sår eller rifter på hænderne. Tryk på de rigtige billeder.`,
			en: `In the previous task, an ordinary brown band aid was a mistake. So what should you use instead, if you wounds or scratches on your hands. Click on the correct images. `
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Hånd med alm plaster og handske
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Hånd med fast forbinding og handske
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Hånd med handske og sår under
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Hånd med fast forbinding
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-if-you-got-wounds',
		background: 'dressing-room',
		characters: ['manager coat-2'],
		text: {
			da: `Hvis du har betændte sår og ikke beskytter ordentligt, så kan stafylokokker fra såret give fødevareforgiftning. `,
			en: `If you have infected wounds and don't cover them properly, staphylococci bacteria from the wound can cause food poisining.`,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-bring-your-phone',
		background: 'dressing-room',
		text: {
			da: `Må du godt tage din mobiltelefon med ind?`,
			en: `Are you allowed to bring your cell phone?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Ja`, 
					en: `Yes`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja, det må du faktisk gerne. Men har du tænkt over, hvornår du sidst har desinficeret den?`,
								en: `Yes, that is actually allowed. But when was the last time you desinfected it?`
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Nej`, 
					en: `No`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Kun hvis det er en iPhone`, 
					en: `Only if it's an iPhone`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Samme regler gælder for alle telefoner. `,
								en: `Same rules apply to all phones`
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-not-as-planned',
		background: 'dressing-room',
		characters: ['manager coat-2'],
		text: {
			da: `Nogle gange går tingene ikke helt som planlagt. Lad os tage et par eksempler.`,
			en: `Some times things don't go entirely as planned. Let's go through a couple of examples. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-roof-plates',
		background: 'dressing-room',
		text: {
			da: `Mens du står i slusen, dukker der en håndværker op. Han siger, han skal ind for at fixe nogle loftsplader. Hvad er vigtigst at gøre?
			<br /><br /><b>Vælg de 2 rigtige:</b>`,
			en: `When you are in the sluice, a carpenter enters. He says he's there to fix some tiles in the ceiling. What are the two most important things you should do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Bede ham tage en kittel på og følge øvrige beklædningskrav`, 
					en: `Ask him to wear a lab coat and adhere to other rules for how to dress`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det er rigtigt at tænke på påklædning. Men der er noget som er vigtigere. `,
								en: `It's absolutely right to think about his clothes. But one thing is even more important. `
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `At lukke ham ind - loftet skal fixes`, 
					en: `Let him in - the ceiling must be fixed`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det er vigtigt at få repareret loftet, men det skal ske på en måde så produkter ikke kontamineres. `,
								en: `It is important, yes. But it has to be done in a way so that our products don't get contaminated.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Checke med den laboratorieansvarlige, at der er risikovurderet og truffet passende foranstaltninger`, 
					en: `Check with the lab manager that you've made a risk assessment and taken necessary precautions`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig. Der må ikke foregå reparationsarbejde samtidig med produktion, hvis der er fare for kontaminering. Det lyder som om, der skulle have været bedre kommunikation om besøget.`,
								en: `Exactly. No service is to be done while production is ongoing, if there is a danger of contamination. It sounds like communication about this visit could have been better.`
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Sig, at han må løse opgaven i frokostpausen`, 
					en: `Tell him to do it during the lunch break`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det er ikke en god idé. For der er ikke tid til en rengøring, før produktionen går i gang igen.`,
								en: `That is not a good idea. There won't be time for cleaning before production resumes. `
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-ready-to-yellow',
		background: 'dressing-room',
		text: {
			da: `Du er klar til at gå ind i gul zone. En person kalder udefra og siger: "der er kage!" Du vil gerne have kage, så hvad gør du?`,
			en: `You are ready to enter a yellow zone. A person calls from outside and says: "Time for cake!" You would like some cake, so what do you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Henter et stykke`, 
					en: `Get a slice of cake`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Beder kollegaen stille et stykke i kølerummet til senere`, 
					en: `Ask my colleague to place a slice in the cooler for later`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Beder kollegaen stille et stykke i caféområdet til senere`, 
					en: `Ask my colleague to place a slice in the cafeteria for later`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja! Privat mad må ikke være i gule og grønne zoner.`,
								en: `Yes! Private food is not allowed in green or yellow zones. `
							}
						})
					})
				],
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-just-one-last-thing',
		background: 'dressing-room',
		characters: ['manager coat-2'],
		text: {
			da: `Godt. Vi har lige en sidste ting, inden vi går ind i selve zonen. `,
			en: `Nice. One final thing before we enter the production areas. `,
		}
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-1',
		shuffleOptions: false,
		text: {
			da: `Er du bekendt med følgende? Du må ikke arbejde i gul zone, hvis du har en sygdom, som kan smitte via fødevarer - det kan for eksempel være roskildesyge`,
			en: `Are you aware of the following? You are not allowed to work in a yellow zone if you have a disease that can be transmitted via food - for instance stomach flu.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Ja, det er jeg klar over`,
					en: `Yes, I am aware of that`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt. Hvis vi ved den slags, kan vi bedre sikre en høj hygiejnestandard. Og du skal faktisk være symptomfri i 48 timer før du må arbejde i en gul zone.`,
								en: `Good. By knowing things like this, we can maintain high hygiene standards. And you actually have to be free of symptomms for 48 hours before working in a yellow zone. `
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Nej, det vidste jeg ikke`,
					en: `No, I did not know that`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt du siger det. Når du er færdig med modulet her, kan du læse mere i QA Instruktion 19. Så ved du, hvordan du skal forholde dig.`,
								en: `It's good that you say so. After completing this module, read more in QA instruction 19. Then you know what to do in the future. `
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-finaly-five-more',
		background: 'dressing-room',
		characters: ['manager coat-2'],
		text: {
			da: `Til allersidst er der fem spørgsmål om vores fødevaresikkerhedskultur. Her er der ikke rigtige og forkerte svar. Spørgsmålene giver ikke point, og besvarelserne er anonyme. `,
			en: `Finally we have five questions about our food safety culture. There are no right and wrong answers. The questions do you earn you points, and you answer anonymously. `,
		}
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's2-m2-survey-how-much-do-you-agree',
		shuffleOptions: false,
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg har myndighed og pligt til at reagere, hvis jeg ser, at der er risiko for fødevaresikkerheden?`,
			en: `How strongly do you agree with the following?
				<br />I'm allowed and required to act if I see something that can jeopardise food safety.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's2-m2-survey-how-much-do-you-agree-2',
		shuffleOptions: false,
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg reagerer, hvis jeg ser andre, der ikke følger hygiejnereglerne?`,
			en: `How strongly do you agree with the following?
				<br />I react if I see others who do not follow our hygiene rules.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's2-m2-survey-how-much-do-you-agree-3',
		shuffleOptions: false,
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg ser et fødevaresikkerhedsproblem, så løser jeg det eller informerer min nærmeste leder?`,
			en: `How strongly do you agree with the following?
				<br />If I see a food safety problem, I solve it or inform my immediate manager.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's2-m2-survey-how-much-do-you-agree-4',
		shuffleOptions: false,
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg prøver hele tiden på at gøre vores arbejdsgange bedre i forhold til fødevaresikkerhed.`,
			en: `How strongly do you agree with the following?
				<br />I continously try to improve our way of working when it comes to food safety?`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's2-m2-survey-how-much-do-you-agree-5',
		shuffleOptions: false,
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg rapporterer en risiko for fødevaresikkerheden, så bliver der gjort noget ved det?`,
			en: `How strongly do you agree with the following?
				<br />If I report about a risk to food safety, it will be acted upon.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-finished-first-module',
		background: 'dressing-room',
		characters: ['manager coat-2'],
		text: {
			da: `Du er nu igennem modulet. Hvis du har fået tre stjerner, så er du færdig med træningen. Hvis ikke, så må du tage modulet en gang til. `,
			en: `You are now through the module. If you have three stars, you have completed the training. If not, you have to complete this module once more. `,
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's2-m2-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};