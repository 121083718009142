let env = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let gameUrl = 'http://localhost:3000';
let apiURL = 'http://localhost:8080/api/';
let usersDbName = 'users';
let gamesDbName = 'games';
let playersDbName = 'players';
let surveysDbName = 'surveys';
let minSurveyParticipants = 1;
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-iff-test.web.app';
	apiURL = 'https://cgl-iff-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-iff-demo.web.app';
		apiURL = 'https://cgl-iff-demo.web.app/api/';
		gamesDbName = 'games-demo';
		playersDbName = 'players-demo';
		surveysDbName = 'surveys-demo';
		minSurveyParticipants = 5;
	}
}
if (env === 'production') {
	gameUrl = 'iffhygienetraining.dk';
	apiURL = 'https://cgl-iff-production.web.app/api/';
	minSurveyParticipants = 5;
}



const appConfig = {
	env,
	usersDbName,
	gamesDbName,
	playersDbName,
	surveysDbName,
	gameUrl,
	apiURL,	
	name: 'IFF',
	faclitatorSectionUri: 'facilitator',
	cookiesAcceptedCookieName: 'iff_cookies_accepted',
	screenBaseWidth: 1920,
	defaultLanguage: 'da',
	minEmployeeIdLength: 4,
	maxEmployeeIdLength: 7,
	maxStarsPerModule: 3,
	inactivityLimitSeconds: 30,
	moduleDisabledDays: 7,
	minSurveyParticipants
};

export default appConfig;