import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {loginUiTexts} from 'data/ui-texts/login-ui-texts';
import Button from 'components/ui/button/button';
import './login-player.scss';

const LoginPlayer = (props) => {
	const {
		isLoading,
		languageId,
		playerLoginType,
		userId,
		name,
		email,
		feedback,
		handleInput,
		handleConfirmEmail,
		handleLoginWithNameEmail,
		handleConfirmEmployeeIdPopup,
	} = props;

	if (playerLoginType === 'name-email') {
		return (
			<div className="LoginPlayer">
				<form className="LoginPlayer-form" onSubmit={(e) => {handleLoginWithNameEmail(e);}}>
					<input
						name="name"
						type="text"
						placeholder={getText(loginUiTexts.name, languageId)} 
						autoComplete="section-playerInfo name"
						value={name ? name : ''}
						onChange={(event)=>{handleInput(event);}}
					/>
					<input
						name="email"
						type="text"
						placeholder={getText(loginUiTexts.email, languageId)} 
						autoComplete="section-playerInfo email"
						value={email ? email : ''}
						onChange={(event)=>{handleInput(event);}}
					/>
					<div className="LoginPlayer-loginBtn">
						<Button 
							isLoading={isLoading}
							type="submit"
							classes={['login']}
							text={getText(loginUiTexts.start, languageId)}
							onClick={(e) => {handleLoginWithNameEmail(e);}}
						/>
					</div>
					<div className="LoginPlayer-errorMessage"><span>{feedback}</span></div>
				</form>
			</div>
		);
	} else if (playerLoginType === 'email') {
		return (
			<div className="LoginPlayer">
				<form className="LoginPlayer-form" onSubmit={(e) => {handleConfirmEmail(e);}}>
					<input
						name="email"
						type="text"
						placeholder={getText(loginUiTexts.email, languageId)} 
						autoComplete="section-playerInfo email"
						value={email ? email : ''}
						onChange={(event)=>{handleInput(event);}}
					/>
					<div className="LoginPlayer-loginBtn">
						<Button 
							isLoading={isLoading}
							type="submit"
							classes={['login']}
							text={getText(loginUiTexts.start, languageId)}
							onClick={(e) => {handleConfirmEmail(e);}}
						/>
					</div>
					<div className="LoginPlayer-errorMessage"><span>{feedback}</span></div>
				</form>
			</div>
		);
	}
	
	return (
		<div className="LoginPlayer">
			<form className="LoginPlayer-form" onSubmit={(e) => {handleConfirmEmployeeIdPopup(e);}}>
				<input
					name="userId"
					type="text"
					placeholder={getText(loginUiTexts.userId, languageId)} 
					autoComplete="section-grouplogin userId"
					value={userId ? userId : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<div className="LoginPlayer-loginBtn">
					<Button 
						isLoading={isLoading}
						type="submit"
						classes={['login']}
						text={getText(loginUiTexts.start, languageId)}
						onClick={(e) => {handleConfirmEmployeeIdPopup(e);}}
					/>
				</div>
				<div className="LoginPlayer-errorMessage"><span>{feedback}</span></div>
			</form>
		</div>
	);
};

LoginPlayer.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	playerLoginType: PropTypes.string.isRequired,
	userId: PropTypes.any,
	name: PropTypes.string,
	email: PropTypes.string,
	feedback: PropTypes.string,
	handleInput: PropTypes.func.isRequired,
	handleConfirmEmail: PropTypes.func.isRequired,
	handleLoginWithNameEmail: PropTypes.func.isRequired,
	handleConfirmEmployeeIdPopup: PropTypes.func.isRequired,
};

export default LoginPlayer;