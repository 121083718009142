const scenariosData = [
	{
		id: 'scenario-1',
		playerLoginType: 'email',
		title: {
			da: 'Grundlæggende hygiejnetræning',
			en: 'Basic hygiene training',
		},
		languageIds: ['da', 'en'],
		modulesData: require('data/scenarios/scenario-1/modules-data').modulesData,
		moduleGroups: require('data/scenarios/scenario-1/modules-data').moduleGroups,
	},
	{
		id: 'scenario-2',
		playerLoginType: 'email',
		title: {
			da: 'Lille hygiejnetræning',
			en: 'Small hygiene training',
		},
		languageIds: ['da', 'en'],
		modulesData: require('data/scenarios/scenario-2/modules-data').modulesData,
		moduleGroups: require('data/scenarios/scenario-2/modules-data').moduleGroups,
	},
];

export {
	scenariosData
};