import React from 'react';
import PropTypes from 'prop-types';
import {getSurveyResults} from 'helpers/survey-helper';
import {getText} from 'helpers/language-helper';
import {renderMarkdown} from 'helpers/text-helper';
import {facilitatorUiTexts} from 'data/ui-texts/facilitator-ui-texts';
import './survey-results.scss';

const SurveyResults = ({languageId, surveyId, surveysData, surveysResponses}) => {
	
	/* Get data for selected survey */
	const surveyData = surveysData.find((s) => {return s.id === surveyId;});

	const filteredResponses = surveysResponses.filter((response) => {
		return response.selectedOptionIds && response.selectedOptionIds.length > 0;
	});

	/* Get results for selected surveyId, filtering off empty data if any */
	const surveyResults = getSurveyResults(surveyId, surveysData, filteredResponses);

	return (
		<div className='SurveyResults'>
			{/* Question */}
			<div className="SurveyResults-question">
				<span>{getText(facilitatorUiTexts.question, languageId)}:</span>
				{renderMarkdown(getText(surveyData.text, languageId))}
			</div>

			{/* No survey result */}
			{!surveyResults && <div className="SurveyResults-noResults">
				<span>{getText(facilitatorUiTexts.noEnoughSurveyResponses, languageId)}</span>
			</div>}

			{/* Survey result */}
			<div className="SurveyResults-results">
				{surveyResults && surveyResults.options.map((option) => {
					const optionData = surveyData.options.find((o) => {return o.id === option.id;});
					if (!optionData) return null;

					const optionPercentage = (surveyResults.numberOfResponses > 0
						? Math.round((option.answers / surveyResults.numberOfResponses) * 100)
						: 0
					);

					return (
						<div key={option.id} className="SurveyResults-option">
							<div className="SurveyResults-optionText">
								<span>{getText(optionData.text, languageId)}</span>
							</div>
							<div className="SurveyResults-optionPercentage">
								<span>{optionPercentage}%</span>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

SurveyResults.propTypes = {
	languageId: PropTypes.string.isRequired,
	surveyId: PropTypes.string.isRequired,
	surveysData: PropTypes.array.isRequired,
	surveysResponses: PropTypes.array.isRequired,
};

export default SurveyResults;
