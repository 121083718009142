const modulesData = [
	{
		id: 's2-m1',
		background: 'outside',
		title: {
			da: 'Lille hygiejnetræning',
			en: 'Small hygiene training'
		},
		minStars: 3,
		requiredModuleIds: [],
		tasks: require('./modules/module-1').moduleTasks,
	},
	{
		id: 's2-m2',
		background: 'production',
		title: {
			da: 'Lille gentræning',
			en: 'Small retraining'
		},
		minStars: 3,
		requiredModuleIds: ['s2-m1'],
		tasks: require('./modules/module-2').moduleTasks,
	},
]

const moduleGroups = [
	{
		id: 's2-mg1',
		courseNumber: 'xxxxx',
		title: {
			da: 'Lille hygiejnetræning',
			en: 'Small hygiene training'
		},
		moduleIds: [
			's2-m1',
			's2-m2'
		]
	},
];

export {
	modulesData,
	moduleGroups
};