const modulesData = [
	{
		id: 's1-m1',
		background: 'dressing-room',
		title: {
			da: 'Hygiejnetræning A',
			en: 'Hygiene training A'
		},
		minStars: 3,
		requiredModuleIds: [],
		tasks: require('./modules/module-1').moduleTasks,
	},
	{
		id: 's1-m2',
		background: 'production',
		title: {
			da: 'Hygiejnetræning B',
			en: 'Hygiene training B'
		},
		minStars: 3,
		requiredModuleIds: ['s1-m1'],
		tasks: require('./modules/module-2').moduleTasks,
	},
	{
		id: 's1-m3',
		background: 'outside',
		title: {
			da: 'Gentræning A',
			en: 'Retraining A'
		},
		minStars: 3,
		requiredModuleIds: ['s1-m2'],
		tasks: require('./modules/module-3').moduleTasks,
	},
	{
		id: 's1-m4',
		background: 'production',
		title: {
			da: 'Gentræning B',
			en: 'Retraining B'
		},
		minStars: 3,
		requiredModuleIds: ['s1-m3'],
		tasks: require('./modules/module-4').moduleTasks,
	},
]

const moduleGroups = [
	{
		id: 's1-mg1',
		courseNumber: 'xxxxx',
		title: {
			da: 'Hygiejnetræning',
			en: 'Hygiene training'
		},
		moduleIds: [
			's1-m1',
			's1-m2'
		]
	},
	{
		id: 's1-mg2',
		courseNumber: 'xxxxx',
		title: {
			da: 'Gentræning',
			en: 'Retraining'
		},
		moduleIds: [
			's1-m3',
			's1-m4'
		]
	},
];

export {
	modulesData,
	moduleGroups
};