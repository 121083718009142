const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-welcome-second-module',
		showProgressBar: false,
		background: 'dressing-room',
		characters: ['manager coat-2 hairnet-1'],
		text: {
			da: `Velkommen til anden del af hygiejnetræningen. Nu skal det handle om alt det, der foregår inde i selve hygiejnezonen. `,
			en: `Welcome to the second part of the hygiene training. Now we'll look at what's going on inside the hygiene zone. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-lets-start',
		background: 'dressing-room',
		characters: ['manager coat-1 hairnet-1'],
		text: {
			da: `Lad os kaste os ud i det. Husk, du skal have tre stjerner for at gennemføre et modul. `,
			en: `Let's begin! And remember, you need three stars to complete a module. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-when-washing-hands',
		background: 'dressing-room',
		shuffleOptions: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejnen, når vi producerer fødevarer?`,
			en: `Why must we be extra aware of hygiene when producing foodstuffs?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `På grund af lovkrav`, 
					en: `Because of legal requirements`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`, 
					en: `Because poor hygiene can make people sick`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `For at sikre høj kvalitet`, 
					en: `To ensure high quality`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Fordi kunderne forventer det`, 
					en: `Because our customers expect it`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Af alle ovenstående grunde`, 
					en: `For all above reasons`,
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-closer-look-production',
		background: 'pilot-plant',
		characters: ['manager coat-1 hairnet-1'],
		text: {
			da: `Lad os kigge nærmere på produktionen. På næste billede er vi i Pilot plant - altså i gul zone. Her skal du spotte de ting, som er problematiske i forhold til hygiejne.`,
			en: `Let's take a closer look at a production area. On the next screen, we'll be in Pilot plant - that is, a yellow zone. See if you can spot the things that are problematic when it comes to hygiene. `,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-pilot-plant',
		layout: 's1-m2-pilot-plant',
		background: 'pilot-plant',
		backgroundModifier: 'moved-left',
		text: {
			da: `Tryk på de 5 fejl. Tryk OK når du er klar, eller hvis du ikke kan finde dem alle. `,
			en: `Click on the 5 issues. Click OK when you are ready, or if you can't find them all`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'dirty-box', // Beskidt papkasse på gulv
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'coffee-cup' // Kaffekop
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'person-handbag', // Person har håndtaske med
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'flower', // Der står en blomst i vindueskarmen
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'drip', // Dryp fra aircondition
				type: 'feedback',
				feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
					text: {
						da: `Godt set! Husk at indrapportere den som near miss!`,
						en: `Nice catch! Remember to report it as a near miss. `
					}
				})
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-challenge-in-production',
		background: 'pilot-plant',
		backgroundModifier: 'moved-left',
		characters: ['manager coat-1 hairnet-1'],
		text: {
			da: `En af udfordringerne i produktionen er at sikre kvaliteten af de prøver, vi arbejder med. Lad os kigge på et par eksempler.`,
			en: `One of the challenges in production areas is to ensure that our samples are of high quality. Let's take a look at a couple of examples. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-123',
		background: 'pilot-plant',
		backgroundModifier: 'moved-left',
		shuffleOptions: false,
		text: {
			da: `Du har modtaget en prøve, der bare er mærket "123". Må du bruge den i produktionen?`,
			en: `You have received a sample labelled with "123". Are you allowed to use that in production?`
		}, 
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Ja`, 
					en: `Yes`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Nej`, 
					en: `No`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Korrekt. Den skal kasseres, fordi du ikke kan være sikker på, hvad der reelt er i den, og fordi sporbarhed ikke kan sikres.`,
								en: `Exactly. You have to discard it because you can't be certain, what's actually in it, and because you can't ensure it's traceability`
							}
						})
					})
				],
				isCorrect: true
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-haccp-document',
		background: 'pilot-plant',
		backgroundModifier: 'moved-left',
		text: {
			da: `Du har fremstillet en prøve og aflæst temperaturen til 70°C. Fra dit HACCP-dokument, kan du se, at der er tale om et CCP, og at temperaturen skal være 72°C i mindst 15 sekunder. Hvad gør du?
			<br /><br />Hvilke 3 svar kan være rigtige?`,
			en: `You have made a sample and measured the temperature to 70°C. In your HACCP-document, you can see that it's a CCP, and that the temperature must be 72°C for at least 15 seconds. What do you do?
			<br /><br />Which 3 answers can be correct?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				isCorrect: true,
				text: {
					da: `Genbehandler den`, 
					en: `Reprocess it`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Hvis du gør det, så skal du huske at risikovurdere og dokumentere, hvad der er gjort. Se QA Instruktion 20`,
								en: `If you do so, remember to make a risk assessment and document your actions. See QA instruction 20`
							}
						})
					}),
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: true,
				text: {
					da: `Kasserer den`, 
					en: `Discard it`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det er den rigtige beslutning, hvis du har foretaget en risikovurdering, hvor du er kommet frem til, at produktet ikke er sikkert. `,
								en: `If you have made a risk assessment and come to the conclusion that the product isn't safe, this is the right decision. `
							}
						})
					}),
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: true,
				text: {
					da: `Mærker den instrumental testing only`, 
					en: `Mark it as instrumental testing only`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Hvis prøven skal gemmes, men ikke er sikker at smage, er dette vigtigt.`,
								en: `If you want to save the sample, but it's not safe for tasting, this is the right choice. `
							}
						})
					}),
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Fortsætter, kunden skal snart bruge prøven`, 
					en: `Continue. The client needs the sample soon`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Dårligt valg. Du skal ikke tage chancer med fødevaresikkerhed.`,
								en: `Bad choice. Don't take risks when it comes to food safety.`
							}
						})
					}),
				],
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-important-contamination',
		background: 'pilot-plant',
		backgroundModifier: 'moved-left',
		characters: ['manager coat-2 hairnet-1'],
		text: {
			da: `Et vigtigt opmærksomhedspunkt er kontaminering - altså at der ikke kommer noget i vores produkter, som ikke skal være der. `,
			en: `An important thing to be aware of is contamination - that is, when things get into our products that aren't supposed to be there. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-why-contamination-problem',
		background: 'pilot-plant',
		backgroundModifier: 'moved-left',
		text: {
			da: `Hvorfor er kontaminering et problem?`,
			en: `Why is contamination an issue?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Det kan være farligt`, 
					en: `It can be dangerous`,
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Det kan ødelægge smagen`, 
					en: `It can ruin the taste`,
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Det kan give utilfredse kunder`, 
					en: `It can cause unhappy customers`,
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Det udleder mere CO2`, 
					en: `It emits more CO2`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Kontaminering er kun et problem, hvis auditører ser det`, 
					en: `Contamination is only an issue if auditors see it`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Det kan gøre vores tøj beskidt`, 
					en: `It can make our clothes dirty`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: {
					da: `Kontaminering med allergener kan gøre folk syge`, 
					en: `Contamination with allergens can make people sick`,
				},
				isCorrect: true
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-how-you-should-react',
		background: 'pilot-plant',
		backgroundModifier: 'moved-left',
		characters: ['manager coat-2 hairnet-1'],
		text: {
			da: `Hvordan du skal reagere på kontaminering, afhænger af typen - altså om det er mikrobiologisk, fysisk eller kemisk. `,
			en: `How to react to contamination depends on the type. That is, whether it's microbilogical, physical, or chemical. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-you-need-an-ingredient',
		background: 'pilot-plant',
		backgroundModifier: 'moved-left',
		text: {
			da: `Du skal bruge en ingrediens i et forsøg. Du opdager, at der er fremmedlegemer i sækken. Hvad gør du?`,
			en: `You need an ingredient in a test. You discover foreign bodies in the bag. What do you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Kasserer varen`, 
					en: `Discard the product`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det er nok det bedste at gøre. Forudsætninger for andre muligheder er beskrevet i QA Instruktion 12 om styring af afvigende produkter.`,
								en: `That is probably the best solution. Other options are noted in QA instruction 12 about controlling non conforming products`
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Kontakter leverandøren og fortæller om problemet `, 
					en: `Contact supplier and tell them about the issue`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt. Så kan de undersøge nærmere og vurdere, om de skal tilbagekalde, hvis det er farligt. `,
								en: `Good. Then they can make an investigation and decide to recall if it is dangerous. `
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Sigter det og smider kontaminanten ud så ingrediensen kan bruges`, 
					en: `Sift it and discard the contaminant so the ingredient can be used`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Du ved ikke, om resten af materialet er kontamineret. `,
								en: `You don't know if the rest of the material has been contaminated. `
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Undersøger om der er fremmedlegemer i flere sække`, 
					en: `Check if other bags contain foreign bodies`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt. Husk at mærke dem op som afvigende, hvis ikke de kasseres.`,
								en: `Good. Remember to mark them as non conforming if you don't discard them.`
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Opretter en food safety near miss `, 
					en: `Report a food safety near miss`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `God idé. Det er specielt vigtigt, hvis det kunne have ført til personskade eller sygdom ved indtagelse.`,
								en: `Good idea! It's especially important if it could have let to personal injury or illness if consumed. `
							}
						})
					})
				],
				isCorrect: true
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-two-people-on-image',
		layout: 's1-m2-two-people-on-image',
		background: 'pilot-plant',
		backgroundModifier: 'moved-left',
		text: {
			da: `Hvis de to personer på billedet skal til møde eller til frokost, hvad skal de så gøre med deres tøj?`,
			en: `If the two persons pictured has to leave for a meeting or lunch, what should they do with their clothes?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Smide kitlen til vask`, 
					en: `Put the lab coat in laundry`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja. Man kan også hænge den til genbrug, hvis man kommer tilbage igen. `,
								en: `Yes. You can also hang it for reuse if you plan to return.`
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Beskytte t-shirten ved at tage jakke over`, 
					en: `Protect the t-shirt by wearing a jacket on top`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Netop. Husk, det skal være en jakke, der er udleveret af firmaet, (og som vaskes hos Elis)`,
								en: `Exactly. Remember it has to be a jacket given to you by the company (and which is laundered by Elis).`
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Vaske det med en klud`, 
					en: `Wash it with a cloth`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Ikke noget`, 
					en: `Nothing`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Kitler må ikke bæres i kantinen eller mødelokaler. Din arbejds-t-shirt skal dækkes med en firmajakke for at holde det rent. Se QA Instruktion 17.`,
								en: `Lab coats are not allowed in the cafeteria or meeting rooms. You must cover your work t-shirt with a company jacket to keep it clean. See QA instruction 17.`
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-when-moving-around-IFF',
		layout: 's1-m2-when-moving-around-IFF',
		subtype: 'images',
		background: 'production',
		text: {
			da: `Når du bevæger dig rundt på IFF er der nogle områder, hvor du skal være særligt opmærksom og skifte sko. Tryk på de relevante bygninger. `,
			en: `When walking around IFF, you need to be especially aware in some areas and remember to change your shoes. Click on the buildings where this applies.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Indgang til bygning M
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Helt rigtigt. Her skal du være opmærksom på at skifte sko i slusen, inden du går i piloten. `,
								en: `Precisely. Here you must remember to change your shoes in the sluice before entering the pilot. `
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Bygning R
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Her skal du skifte sko i slusen, inden du går i piloten`,
								en: `Here you must change your shoes before entering the pilot. `
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3 // Bygning O
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4 // Bygning R
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5 // Bygning T
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m2-order-walk-in-green-warehouse',
		background: 'production',
		text: {
			da: `Du går ind i et grønt lagerrum med en pose mandelmel. Du lægger mærke til, at den har drysset på gulvet. Hvad skal du gøre? Sæt handlingerne i den rigtige rækkefølge. Tryk OK, når du er klar. `,
			en: `You enter a green storage room with a bag of almond flour. You notice a spill on the floor from it. What should you do? Place the actions below in the right order. Click OK when you are ready. `
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Emballér forsvarligt`,
					en: `Package securely`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Mærk som allergenholdig`,
					en: `Mark as containing allergens`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Stil på nederste hylde`,
					en: `Place on the lowest shelf`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Tør op`,
					en: `Clean up`
				},
				correctOrderNumbers: [4]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-re-package',
		background: 'production',
		characters: ['manager coat-1 hairnet-1'],
		text: {
			da: `Det er en god idé at om-emballere først. Så drysser der ikke mere mel ud, mens du tørrer op.  Men vent ikke for længe med at tørre op`,
			en: `It's a good idea to repackage first. Then you won't spill more flour while you clean up. But don't wait for too long with the cleaning. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-when-orange-allergimarks',
		background: 'production',
		text: {
			da: `Hvornår skal man egentlig bruge de orange allergenmærker?`,
			en: `When should you be using the orange allergen labels?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `På særlige fødevareingredienser opbevaret i gule eller grønne zoner`, 
					en: `On certain food ingredients stored in yellow or green zones`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Se nærmere i bilag 6 i QA Instruktion 20.`,
								en: `See more in appendix 6 in QA instruction 20.`
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `På alle enzymer`, 
					en: `On all enzymes`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nej. De orange mærker er kun beregnet til brug på ingredienser, der skal indgå i fødevarer, og som kan give en allergisk reaktion ved indtagelse. Relevante allergener fremgår af bilag til QA Instruktion 20.`,
								en: `No. The orange labels are reserved for ingredients for foodstuffs and which can cause an allergic reaction when consumed. The allergens in question can be found in the appendix to QA instruction 20.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `På latex handsker`, 
					en: `On latex gloves`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nej. De orange mærker er kun beregnet til brug på ingredienser, der skal indgå i fødevarer, og som kan give en allergisk reaktion ved indtagelse. Relevante allergener fremgår af bilag til QA Instruktion 20.`,
								en: `No. The orangel abels are reserved for ingredients for foodstuffs and which can cause an allergic reaction when consumed. The allergens in question can be found in the appendix to QA instruction 20.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `På levnedsmiddelprøver, der indeholder allergener`, 
					en: `On food samples containing allergens`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det er faktisk ikke korrekt, for alle levnedsmiddelprøver fremstillet i Brabrand skal være mærket med en standard allergenadvarsel på etiketten. Se hvilke allergener, der er tale om i bilag 6 til QA Instruktion 20.`,
								en: `Actually, that is not correct since all food samples made in Brabrand must be marked with a standard allergen warning on the label. See the allergens in questions in appendix 6 in QA instruction 20.`
							}
						})
					})
				],
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-nonfood',
		background: 'production',
		text: {
			da: `Du har en prøve af noget nonfood, som skal opbevares koldt. Hvad er reglerne for at opbevare det på et lager?`,
			en: `You have a nonfood sample that needs to be stored in a cool place. What are the rules for storing it in a storage room?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Det må ikke opbevares på gult lager`, 
					en: `Dont' store in a yellow storage area`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Det må ikke opbevares på grønt lager`, 
					en: `Don't store in a green storage area`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Det må ikke opbevares på gule eller grønne lagre`, 
					en: `Don't store in green or yellow storage areas`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Begge er okay`, 
					en: `Green and yellow are both okay`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja. Det er nemlig ikke forbudt at opbevare nonfood materiale i gule eller grønne lagre. Men det skal være skarpt adskilt fra levnedsmidlerne, det må ikke udgøre en kontamineringsrisiko, og det skal være tydeligt mærket 'ikke til konsum'.`,
								en: `Yes. It is in fact permitted to store nonfood material in yellow or green storage areas. But it must be kept separate from foodstuffs, mustn't be a contamination risk, and must be clearly labeled "not for consumption".`
							}
						})
					})
				],
				isCorrect: true
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-water-droplets',
		background: 'production',
		text: {
			da: `I lagerrummet bemærker du, at der drypper kondensvand fra loftet. Hvilken fødevarerisiko kan det lede til?`,
			en: `In the storage room you notice water dripping from the ceiling. What food safety hazard can that cause?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Kemisk forurening`, 
					en: `Chemical contamination`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Mikrobiologisk forurening`, 
					en: `Microbiological contamination`,
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Fysisk forurening`, 
					en: `Physical contamination`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Det er ikke en risiko`, 
					en: `It is not a hazard`,
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-avoid-animals',
		background: 'production',
		characters: ['manager coat-1 hairnet-1'],
		text: {
			da: `Vi SKAL undgå skadedyr i vores produktionslokaler og lagre. `,
			en: `We MUST avoid pests in our production areas and storage rooms. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-mouse',
		background: 'production',
		text: {
			da: `Du spotter en mus på gulvet på lageret. Hvad skal du gøre?`,
			en: `You spot a mouse on the floor in a storage room. What should you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Forsøge at holde øje med musen imens jeg tilkalder hjælp`, 
					en: `Try to keep an eye on the mouse while I call for help`,
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Forsøge at fange musen selv og derefter tilkalde hjælp, hvis det ikke kan lade sig gøre`, 
					en: `Try to catch the mouse and then call for help if unsuccessful`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Sige det til nærmeste leder efter endt arbejdsdag`, 
					en: `Tell my immediate manager at the end of the shift`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Gå ud og lukke døren, så vi ved hvor den er`, 
					en: `Exit and close the door so we know where it is`,
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-previous-assignment',
		background: 'production',
		text: {
			da: `Efter situationen i forrige opgave, hvem skal så orienteres?`,
			en: `After the previous situation, who should you report the incident to?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Facilities`, 
					en: `Facilities`,
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Nærmeste leder`, 
					en: `Immediate manager`,
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `IT afdelingen`, 
					en: `IT`,
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-almost-done-for-the-day',
		background: 'production',
		text: {
			da: `Du er ved at være færdig for i dag. Må du tage dit arbejdstøj med hjem?`,
			en: `You are almost done for the day. Are you allowed to bring your work clothes home with you?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Ja`, 
					en: `Yes`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Nej`, 
					en: `No`,
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Kun hvis det er rent`, 
					en: `Only if they are clean`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Ja, undtagen kitler`, 
					en: `Yes, except for lab coats`,
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-there-are-some-rules',
		background: 'production',
		characters: ['manager coat-1 hairnet-1'],
		text: {
			da: `Der er andre regler, men det her er et godt første skridt. Husk, at hygiejne er noget, du skal være bevidst om hver dag. `,
			en: `There are other rules, but these are important first steps. Remember that hygiene is something you have to be aware of every single day.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-five-questions',
		background: 'production',
		characters: ['manager coat-2 hairnet-1'],
		text: {
			da: `Til allersidst er der fem spørgsmål om vores fødevaresikkerhedskultur. Her er der ikke rigtige og forkerte svar. Spørgsmålene giver ikke point, og besvarelserne er anonyme.`,
			en: `Finally we have five questions about our food safety culture. There are no right and wrong answers. The questions do you earn you points, and you answer anonymously. `,
		}
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-safety-risk',
		shuffleOptions: false,
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg har myndighed og pligt til at reagere, hvis jeg ser, at der er risiko for fødevaresikkerheden?`,
			en: `How strongly do you agree with the following?
				<br />I'm allowed and required to act if I see something that can jeopardise food safety.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-i-react',
		shuffleOptions: false,
		text: {
			da: `Hvor enig er du i følgende:  
				<br />Jeg reagerer, hvis jeg ser andre, der ikke følger hygiejnereglerne?`,
			en: `How strongly do you agree with the following?
				<br />I react if I see others who do not follow our hygiene rules.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-food-safety',
		shuffleOptions: false,
		text: {
			da: `Hvor enig er du i følgende:
				<br />Hvis jeg ser et fødevaresikkerhedsproblem, så løser jeg det eller informerer min nærmeste leder?`,
			en: `How strongly do you agree with the following?
				<br />If I see a food safety problem, I solve it or inform my immediate manager.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-how-much-do-you-agree',
		shuffleOptions: false,
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg prøver hele tiden på at gøre vores arbejdsgange bedre i forhold til fødevaresikkerhed.`,
			en: `How strongly do you agree with the following?
				<br />I continously try to improve our way of working when it comes to food safety?`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-something-will-happen',
		shuffleOptions: false,
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg rapporterer en risiko for fødevaresikkerheden, så bliver der gjort noget ved det?`,
			en: `How strongly do you agree with the following?
				<br />If I report about a risk to food safety, it will be acted upon.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-module-done',
		background: 'production',
		characters: ['manager coat-1 hairnet-1'],
		text: {
			da: `Sådan, du er nu færdig med andet modul. Hvis du har fået tre stjerner, er du færdig med hygiejnetræningen for denne gang. Hvis ikke, så må du tage det her modul en gang til. `,
			en: `That's it, you are now through the second module. If you have three stars, you have completed the hygiene training. If not, you have to retake the module. `,
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m2-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};