/* Always loaded */
const basicImagesData = [
	'backgrounds/outside/animation.svg',
	'backgrounds/outside/factory.svg',
    'backgrounds/outside/outside.svg',
    'backgrounds/outside/trucks.svg',

    'icons/icon-arrow-right.svg',
    'icons/icon-cookies.svg',
    'icons/icon-language.svg',
    'icons/icon-loading-white.svg',
    'icons/icon-loading.svg',
    'icons/icon-lock.svg',
    'icons/icon-logout-2.svg',
    'icons/icon-logout.svg',
    'languages/da.svg',
    'languages/en.svg',
    'bird.svg',
    'logo-cgl.svg',
    'logo-iff.svg',
];

/* Loaded if logged in as player */
const basicGameImagesData = [
	'backgrounds/dressing-room/cabinet.svg',
	'backgrounds/dressing-room/door.svg',
	'backgrounds/dressing-room/floor-boards.svg',
	'backgrounds/dressing-room/lamps.svg',
	'backgrounds/dressing-room/sink.svg',
	'backgrounds/dressing-room/table.svg',
	'backgrounds/pilot-plant/background.svg',
	'backgrounds/pilot-plant/machines.svg',
	'backgrounds/pilot-plant/people.svg',
	'backgrounds/pilot-plant/shelves.svg',
	'backgrounds/pilot-plant/table.svg',
	'backgrounds/production/background.svg',
	'backgrounds/production/shelves.svg',
	'backgrounds/production/table.svg',
    'characters/manager.svg',
    'icons/icon-audio-pause-blue.svg',
    'icons/icon-audio-pause.svg',
    'icons/icon-audio-play-blue.svg',
    'icons/icon-audio-play.svg',
    'icons/icon-checkmark.svg',
    'icons/icon-correct.svg',
    'icons/icon-drag.svg',
    'icons/icon-home.svg',
    'icons/icon-next.svg',
    'icons/icon-star-filled.svg',
    'icons/icon-star.svg',
    'icons/icon-wrong.svg',
];

/* Loaded if logged in as facilitator/admin */
const facilitatorImagesData = [
	'icons/icon-arrow-down.svg',
	'icons/icon-cross-white.svg',
	'icons/icon-sort-asc.svg',
	'icons/icon-sort-desc.svg',
];

const imagesData = {
	'basic': basicImagesData,
	'basic-game': basicGameImagesData,
	'facilitator': facilitatorImagesData,
	's1-m1': require('data/images/s1-m1').moduleImages,
	's1-m2': require('data/images/s1-m2').moduleImages,
	's1-m3': require('data/images/s1-m3').moduleImages,
	's1-m4': require('data/images/s1-m4').moduleImages
};

export {
	imagesData
};
